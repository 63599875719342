<template>
  <div class="add-relative-existing-person-form">
    <input v-model.number="relativeId" placeholder="Relative Person ID" class="input" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      relativeId: null,
    };
  },
  name: 'AddRelativeExistingPersonForm',
};
</script>

<style lang="scss" scoped>
.add-relative-existing-person-form {
  input {
    width: 100%;
  }
}
</style>
