<template>
  <div class="add-relative-new-on-record-form">
    <mcr-loading-indicator v-if="loading" :loading="true"></mcr-loading-indicator>
    <admin-person-edit-form
      v-else
      :form-initial-data="{is_deceased: true}"
      :form-loading="formLoading"
      :show-relatives-json-field="false"
      @save="$emit('save', $event)"
      @cancel="onCancel"
    ></admin-person-edit-form>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';

import AdminPersonEditForm from '@/components/common/forms/admin/AdminPersonEditForm';

export default {
  name: 'AddRelativeNewOnRecordForm',
  props: {
    formLoading: Boolean,
  },
  computed: {
    loading() {
      return isEmpty(this.$store.getters.familyTreePersonOptionsState);
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
  },
  components: {AdminPersonEditForm},
};
</script>

<style scoped></style>
